var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "content-box" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c("i-header", {
            attrs: { title: "选择授权人", showBack: true },
            on: {
              "back-event": function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c("i-search", {
            attrs: {
              shape: "round",
              clearable: "",
              "clear-trigger": "always",
              placeholder: "请输入姓名/拼音/邮箱/员工号",
            },
            on: { input: _vm.onSearch },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _vm.dataList.length
        ? _c(
            "i-list",
            {
              staticClass: "cnt-box",
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.dataList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.confirmItem(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("i-icon", {
                        attrs: {
                          color: item.isChecked
                            ? "var(--themeColor)"
                            : "#DEDEDE",
                          name: "icon-checkbox2",
                          size: 18,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.name) + " "),
                      _c("div", { staticClass: "sub_title" }, [
                        _vm._v(_vm._s(item.extId)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "desc",
                        staticStyle: { "margin-top": "0.08rem" },
                      },
                      [
                        _c("i-icon", {
                          attrs: {
                            size: "14",
                            color: "#13161B",
                            name: "icon-qita",
                          },
                        }),
                        _vm._v(_vm._s(_vm.handleTenantType(item) || "-")),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "desc" },
                      [
                        _c("i-icon", {
                          attrs: {
                            size: "14",
                            color: "#13161B",
                            name: "icon-youxiang",
                          },
                        }),
                        _vm._v(_vm._s(item.email || "（暂无）")),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.dataList.length ? _c("empty-box") : _vm._e(),
      _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function ($event) {
                return _vm.onSubmit()
              },
            },
          },
          [_vm._v("确定")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }